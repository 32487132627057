import axios from "axios"

function appendUrl(method, url, data = {}) {
    const apiHost = `https://api.eyegroup.cc`
    method = method.toLocaleLowerCase();
    if (!['post', 'get'].includes(method)) {
        throw('不允许的请求类型')
    }

    // data.token = localStorage.getItem('access_token') ?? ''
    switch (method) {
        case 'get': {
            return `${apiHost}/${url}?` + Object.keys(data).map(k => `${k}=${data[k]}`).join('&')
        }
        case 'post': {
            return `${apiHost}/${url}`
        }
    }
}

function requestSuccess(res, resolve, reject) {
    if (res.status !== 200) {
        reject({
            code: res.status,
            msg: '网络错误',
            data: res
        })
    } else if (res.data.code !== 200) {
        // if (res.data.code === 10001) {
        //     window.location.href = '/admin#/login'
        // } else {
            reject({
                code: res.data.code,
                msg: res.data.msg,
                data: res
            })
        // }
    } else {
        resolve(res.data)
    }
}

// function getQueryToken() {
//     const r = window.location.href.match(new RegExp('token=(.*)', 'i'))
//     return r ? r[1] : ''
// }

function request(method, url, data = {}) {
    method = method.toLocaleLowerCase();
    if (!['post', 'get'].includes(method)) {
        throw('不允许的请求类型')
    }

    // let token = localStorage.getItem('access_token')
    // if (!token) {
        // const tokenFromParam = getQueryToken()
        // if (tokenFromParam) {
        //     localStorage.setItem('access_token', tokenFromParam)
        //     token = tokenFromParam
        //     window.location.href = window.location.href.split('?token=')[0]
        // } else {
        //     token = ''
        // }
    // }
    // data.token = token

    return new Promise((resolve, reject) => {
        switch (method) {
            case 'get': {
                axios.get(appendUrl(method, url, data)).then(res => {
                    requestSuccess(res, resolve, reject)
                }).catch(e => {
                    console.error('get error', e)
                    reject({
                        code: 50001,
                        msg: '网络错误',
                        data: e
                    })
                })
            } break
            case 'post': {
                axios.post(appendUrl(method, url, data), data).then(res => {
                    requestSuccess(res, resolve, reject)
                }).catch(e => {
                    console.error('post error', e)
                    reject({
                        code: 50001,
                        msg: '网络错误',
                        data: e
                    })
                })
            } break
        }
    })
}

export default request
